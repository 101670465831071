import { FormControlLabel, IconButton, styled, TextField } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Card, Checkbox, Grid, Select, SelectItem, Tooltip, Typography } from '@platform-ui/design-system'
import React, { Dispatch, useRef, useState } from 'react'
import { useStoreContext, Action } from '../../../../Store';
import { SET_META, SET_REDIRECT_URL, SET_STATUS, TOGGLE_MULTIPLE_INSTANCES_META, UPDATE_SERVICE_DATA, UPLOAD_ICON, UPDATE_SERVICE_DATA_FIELD } from '../../actionTypes';
import { PLACEHOLDER_SERVICE_REQUEST } from '../../constants';
import { IntegrationAppFormState } from '../../state';
import Connect from '../../../../Connect/Connect';
import { useFlipperHook } from '../../../../Tables/helpers';

function ServiceConfigurationTab(props: any) {
  const { state, dispatch } = useStoreContext() as { state: IntegrationAppFormState, dispatch: Dispatch<Action> };
  const serviceData = state.service_data || {};
  const multipleInstancesTooltipTitle = `Only applicable for connectors with multiple instances looked up by external_id. Please ignore it if you don't know what that means.`;
  const serviceDataCustomFields = Object.keys(serviceData).filter(key => !(['simple_url_or_path', 'workflow_template'].includes(key)));

  const isValidJSON = (text: string) => {
    try {
      JSON.parse(text);
      return true;
    } catch (error) {
      return false;
    }
  };

  const setProduct = (event) => {
    dispatch({
      type: SET_META,
      payload: {
        meta: {"product": event.target.value}
      }
    });
  };

  const setCategory = (event) => {
    dispatch({
      type: SET_META,
      payload: {
        meta: {"category": event.target.value}
      }
    });
  };

  const setStatus = (event) => {
    dispatch({
      type: SET_STATUS,
      payload: {
        status: event.target.value
      }
    });
  };

  const setRedirectUrl = (event) => {
    dispatch({
      type: SET_REDIRECT_URL,
      payload: {
        redirect_url: event.target.value
      }
    });
  };

  const toggleDirectRedirect = (event) => {
    dispatch({
      type: SET_META,
      payload: {
        meta: {"direct_redirect": !state.meta['direct_redirect']}
      }
    });
  };

  const toggleFeatured = (event) => {
    dispatch({
      type: SET_META,
      payload: {
        meta: {"featured": !state.meta['featured']}
      }
    });
  };

  const toggleMultipleInstances = (event) => {
    dispatch({
      type: TOGGLE_MULTIPLE_INSTANCES_META,
      payload: event.target.checked
    });
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const uploadImage = (event) => {
    dispatch({
      type: UPLOAD_ICON,
      payload: {
        icon: event.target.files[0]
      }
    });
  }

  const ImageWrapper = styled('div')({
    position: 'relative',
    display: 'inline-block',
  });

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleServiceDataFieldChange = (key) => (event) => {
    const value = event.target.value;
    const isJSON = isValidJSON(value);
    dispatch({
      type: UPDATE_SERVICE_DATA_FIELD,
      payload: { key, value, isJSON }
    });
  };

  const toLabel = (text: string) => {
    const result = text.replace(/[_-]/g, ' ');
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  return (
    <Grid container direction="column">
      <Grid item>
      <Card header={'General Data'} id={'general-data'} titleBar body={
        <Grid container>
          <Grid item>
            <ImageWrapper>
              <img
                height="72px"
                width="72px"
                src={state.icon instanceof File ? URL.createObjectURL(state.icon) : state.icon || Connect.public_path() + '/logo_zuora.png'}
                alt="Click to upload"
                onClick={() => fileInputRef.current?.click()}
                style={{ cursor: 'pointer' }}
              />
              <VisuallyHiddenInput type="file" ref={fileInputRef} onChange={uploadImage} />
            </ImageWrapper>
          </Grid>
          <Grid item>
            <Select
              a11yLabel="product"
              dsOnChange={setProduct}
              label="Product"
              placeholder="Select Product"
              required
              value={state.meta['product'] || ''}>
              <SelectItem value='Zuora Billing'>Zuora Billing</SelectItem>
              <SelectItem value='Zuora Revenue'>Zuora Revenue</SelectItem>
              <SelectItem value='Zuora Payments'>Zuora Payments</SelectItem>
              <SelectItem value='Zephyr'>Zephyr</SelectItem>
            </Select>
          </Grid>
          <Grid item>
            <Select a11yLabel="category"
              dsOnChange={setCategory}
              label="Category"
              placeholder="Select Category"
              required
              value={state.meta['category'] || ''}>
              <SelectItem value='CRM'>CRM</SelectItem>
              <SelectItem value='AppStore'>AppStore</SelectItem>
              <SelectItem value='CPQ'>CPQ</SelectItem>
              <SelectItem value='ERP'>ERP</SelectItem>
              <SelectItem value='Data Warehouse'>Data Warehouse</SelectItem>
              <SelectItem value='Payment Gateway'>Payment Gateway</SelectItem>
              <SelectItem value='Query Service'>Query Service</SelectItem>
              <SelectItem value='Cloud Object Storage'>Cloud Object Storage</SelectItem>
              <SelectItem value='Database'>Database</SelectItem>
              <SelectItem value='E-comm'>E-comm</SelectItem>
              <SelectItem value='Coming Soon'>Coming Soon</SelectItem>
            </Select>
          </Grid>
          <Grid item>
            <Select a11yLabel="status"
              dsOnChange={setStatus}
              label="Status"
              placeholder="Select Status"
              required
              value={state.status || '' }>
              <SelectItem value='Public'>Public</SelectItem>
              <SelectItem value='Private'>Private</SelectItem>
            </Select>
          </Grid>
          <Grid item>
            <TextField label='Redirect Url' placeholder="Redirect Url" value={state.redirect_url} onChange={setRedirectUrl} />
          </Grid>
          <Grid item sx={{marginTop: '28px'}}>
            <Checkbox dsOnChange={toggleDirectRedirect} label='Direct Redirect' checked={state.meta['direct_redirect']} />
          </Grid>
          <Grid item sx={{marginTop: '28px'}}>
            <Checkbox
              dsOnChange={toggleMultipleInstances}
              label={
                <Tooltip
                  dsOnClose={() => {}}
                  dsOnOpen={() => {}}
                  e2e='multiple-instances-tooltip'
                  title={<Typography>{multipleInstancesTooltipTitle}</Typography>}
                >
                  {<>Multiple Instances</>}
                </Tooltip>
              }
              checked={!!state.meta['multiple']}
            />
          </Grid>
          <Grid item sx={{marginTop: '28px'}}>
            <Checkbox dsOnChange={toggleFeatured} label='Featured' checked={state.meta['featured']} />
          </Grid>
        </Grid>
      } />
      </Grid>
      <Grid item>
      <Card header={<div style={{ display: 'flex', alignItems: 'center' }}>
            Setting Processing
            <Tooltip title="Configure the Applisting to send settings to a windmill flow or to hit a URL with a post request with all settings in the body."
              dsOnClose={() => { } }
              dsOnOpen={() => { } } e2e={''}>
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>} 
          id='service-data' titleBar body={
        <Grid container direction='column'>
          <Grid item>
            {
              ('name' in state.service_data) &&
                <TextField
                onChange={handleServiceDataFieldChange('icon')}
                label='Icon URL'
                value={state.service_data['icon'] || ''}
                type='text'
                placeholder='https://nav-assets.zuora.life/img/zuora-wordmark.svg'
              />
            }
          </Grid>
          <Grid item>
            <Tooltip
              dsOnClose={() => {}}
              dsOnOpen={() => {}}
              e2e='multiple-instances-tooltip'
              title={<Typography>This will send a post request with all settings information contained to the endpoint or Trigger the Windmill Flow for processing</Typography>}
            >
              <TextField
                onChange={handleServiceDataFieldChange('simple_url_or_path')}
                label='Simple URL or Windmill flow path'
                value={state.service_data['simple_url_or_path'] || ''}
                type='text'
                placeholder='f/staging_example/python_script_example'
              />
            </Tooltip>
          </Grid>
          <Grid item>
            <TextField
              onChange={handleServiceDataFieldChange('workflow_template')}
              label='Workflow Template JSON'
              value={state.service_data['workflow_template'] ? JSON.stringify(state.service_data['workflow_template'], null, 4) : ''}
              type='text'
              placeholder='Workflow Template JSON'
              InputProps={{
                sx: {
                  '& textarea': {
                    resize: 'both'
                  },
                  '&&': {
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    height: 'auto',
                  }
                }
              }}
              minRows={5}
              maxRows={10}
              multiline={true}
            />
          </Grid>
        </Grid>
      } />
      </Grid>
    </Grid>
  )
}

export default ServiceConfigurationTab
